import {
  Flex,
  HStack,
  Text,
  Tooltip,
  Box,
  Divider,
  Collapse,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
  Image,
  InputLeftElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { createUserPostComment } from "@/api/feed/user/comments";
import { SendHorizontal } from "lucide-react";
import {
  createGroupPostComment,
  editGroupPostComment,
  groupPostCommentReport,
  groupPostCommentReactToComment,
} from "@/api/feed/groups/comment";
import { exact } from "prop-types";

export const CommentPanel = ({
  isOpenComment,
  postId,
  triggeredFrom,
  comment,
  setComment,
}) => {
  const { profilePic, _id: uid } = useSelector((state) => state.userData);

  console.log("check profile", profilePic);

  const { _id: groupId } = useSelector((state) => state.groupData);

  const date = new Date();
  const queryClient = useQueryClient();

  const handleComment = () => {
    if (triggeredFrom == "group") {
      const payload = {
        postId: postId,
        uid: uid,
        groupId: groupId,
        timestamp: date.toISOString(),
        comment: comment,
      };
      mutation.mutate(payload);
    } else {
      const payload = {
        postId: postId,
        uid: uid,
        timestamp: date.toISOString(),
        comment: comment,
      };
      mutation.mutate(payload);
    }
  };

  const mutation = useMutation({
    mutationFn: (payload) =>
      triggeredFrom == "group"
        ? createGroupPostComment(payload, uid, groupId)
        : createUserPostComment(payload, uid),
    onMutate: (variables) => {
      return console.log("mutation is happening");
    },
    onError: (error, variables, context) => console.log(error),
    onSuccess: (res, variables, context) => {
      console.log(res);
      setComment("");
      queryClient.invalidateQueries({
        queryKey: ["getAllComments", postId],
        exact: true,
      });
    },
    onSettled: (data, error, variables, context) => {},
  });

  return (
    <Collapse
      in={isOpenComment}
      animateOpacity
      style={{ marginBottom: "20px" }}
    >
      <InputGroup
        size="md"
        border={0}
        style={{ border: "0", borderRadius: "80px" }}
      >
        <Image boxSize="40px" fit="cover" rounded="lg" src={profilePic} />
        <Input
          type="text"
          pr="6.3rem"
          placeholder="Type here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          marginLeft={2}
          outline={0}
          boxShadow={0}
          border={0}
          style={{
            backgroundColor: "#E8E8E8",
            borderRadius: "80px",
            fontSize: "14px",
            boxShadow: "none",
          }}
          _focus={{
            outline: "0 !Important",
            boxShadow: "0px !important",
          }}
        />

        <InputRightElement
          width="100px"
          alignItems="center"
          justifyContent="end"
        >
          <Image alt="img" cursor="pointer" src="/Image.svg" />
          <Text cursor="pointer" style={{}} marginX={2} onClick={handleComment}>
            <SendHorizontal size={18} />
          </Text>
        </InputRightElement>
      </InputGroup>
    </Collapse>
  );
};
