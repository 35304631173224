import React from "react";
// import "./facebook-emojis.css";
// import "@/styles/facebook-emojis.css";

const FacebookEmoji = ({
  type = "like",
  size,
  onClickHandler = undefined,
  lable = "",
}) => {
  const Emoji = emojis[type] || emojis["like"];

  return (
    <div
      id="zama-emoji"
      style={{ height: "30px", padding: "0", margin: "0" }}
      onClick={(e) => {
        if (onClickHandler) {
          onClickHandler(e, lable);
        }
      }}
    >
      <Emoji size={size} />
    </div>
  );
};

const emojis = {
  like: (props) => (
    <div
      className={"zama-emoji emoji--like " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__hand">
        <div className="emoji__thumb" />
      </div>
    </div>
  ),
  heart: (props) => (
    <div
      className={"zama-emoji emoji--love " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__heart" />
    </div>
  ),
  happy: (props) => (
    <div
      className={"zama-emoji emoji--haha " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__face">
        <div className="emoji__eyes" />
        <div className="emoji__mouth">
          <div className="emoji__tongue" />
        </div>
      </div>
    </div>
  ),
  yay: (props) => (
    <div
      className={"zama-emoji emoji--yay " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__face">
        <div className="emoji__eyebrows" />
        <div className="emoji__mouth" />
      </div>
    </div>
  ),
  surprise: (props) => (
    <div
      className={"zama-emoji emoji--wow " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__face">
        <div className="emoji__eyebrows" />
        <div className="emoji__eyes" />
        <div className="emoji__mouth" />
      </div>
    </div>
  ),
  sad: (props) => (
    <div
      className={"zama-emoji emoji--sad " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__face">
        <div className="emoji__eyebrows" />
        <div className="emoji__eyes" />
        <div className="emoji__mouth" />
      </div>
    </div>
  ),
  angry: (props) => (
    <div
      className={"zama-emoji emoji--angry " + (props.size ? props.size : "sm")}
    >
      <div className="emoji__face">
        <div className="emoji__eyebrows" />
        <div className="emoji__eyes" />
        <div className="emoji__mouth" />
      </div>
    </div>
  ),
};

export default FacebookEmoji;
