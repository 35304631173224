import { Flex, HStack, Text, Tooltip, Box } from "@chakra-ui/react";
import { ThumbsUp } from "lucide-react";
import { useState, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  postUserReaction,
  postUserTopReaction,
} from "@/api/feed/user/reaction";
import { groupPostReact } from "@/api/feed/groups/post";
import { reactions } from "@/utilities/comanData";
import FacebookEmoji from "./facebookEmoji";
// import FacebookEmoji from "react-facebook-emoji";

export const ReactionSelector = ({
  postId,
  initialReaction,
  triggeredFrom,
  uid,
  groupId,
  commentLevel,
  // handleMouseLeave,
  // handleMouseEnter,
  // isPanelVisible,
  // setPanelVisibility,
}) => {
  const [selectedReaction, setSelectedReaction] = useState(
    initialReaction ? initialReaction : { reactionType: "no_reaction" }
  );

  const [isPanelVisible, setPanelVisibility] = useState(false);
  const leaveTimeoutRef = useRef(null);
  const queryClient = useQueryClient();

  const debounce = (func, delay) => {
    return () => {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = setTimeout(func, delay);
    };
  };

  const handleMouseLeave = debounce(() => setPanelVisibility(false), 300);
  const handleMouseEnter = () => {
    clearTimeout(leaveTimeoutRef.current);
  };

  const mutation = useMutation({
    mutationFn: (payload) =>
      triggeredFrom === "group"
        ? groupPostReact(payload, uid)
        : commentLevel == "top" || commentLevel == "two"
        ? postUserTopReaction(payload)
        : postUserReaction(payload),
    onSuccess: (res) => {
      console.log("res vhec");
      {
        commentLevel == "top" || commentLevel == "two"
          ? setSelectedReaction({
              reactionType: res?.data?.data.commentReactions.reactionType,
            })
          : setSelectedReaction(res?.data?.data.savedReactions);
      }

      setPanelVisibility(false);

      queryClient.invalidateQueries({
        queryKey: ["getAllReactions", postId],
        exact: true,
      });
    },
  });

  const handleLike = () => {
    if (selectedReaction?.reactionType !== "no_reaction") {
      mutation.mutate({
        postId: postId,
        createdBy: uid,
        reactionType: "no_reaction",
      });
    } else {
      mutation.mutate({ postId: postId, createdBy: uid, reactionType: "like" });
    }
  };

  const handleEmojiHandler = (e, reaction) => {
    const payload =
      triggeredFrom === "group"
        ? { postId: postId, createdBy: uid, reactionType: reaction, groupId }
        : commentLevel == "top" || commentLevel == "two"
        ? { commentId: postId, uid: uid, reactionType: reaction }
        : { postId: postId, createdBy: uid, reactionType: reaction };

    console.log("checking handler", payload);

    mutation.mutate(payload);

    e.stopPropagation();
  };

  const reactionArray = [
    { lable: "like", type: "like" },
    { lable: "happy", type: "happy" },
    { lable: "heart", type: "heart" },
    { lable: "yay", type: "yay" },
    { lable: "angry", type: "angry" },
    { lable: "surprise", type: "surprise" },
    { lable: "sad", type: "sad" },
  ];

  return (
    <HStack
      cursor="pointer"
      onMouseEnter={() => setPanelVisibility(true)}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: "relative",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleLike}
    >
      {selectedReaction?.reactionType !== "no_reaction" ? (
        <>
          <Text
            fontSize={
              commentLevel == "top"
                ? "14px"
                : commentLevel == "two"
                ? " 12px"
                : "19px"
            }
          >
            {
              reactions.find(
                (item) => item?.reactionType === selectedReaction?.reactionType
              )?.reaction
            }
            {/* <FacebookEmoji type={itm.type} /> */}
          </Text>
          <Text
            fontSize={{
              sm: "14px",
              md:
                commentLevel == "top"
                  ? "14px"
                  : commentLevel == "two"
                  ? "12px"
                  : "14px",
            }}
            fontWeight="800"
            color={() =>
              reactions.find(
                (item) => item?.reactionType === selectedReaction?.reactionType
              )?.fontColor
            }
          >
            {selectedReaction?.reactionType?.charAt(0).toUpperCase() +
              selectedReaction?.reactionType?.slice(1)}
          </Text>
        </>
      ) : (
        <>
          {commentLevel !== "two" && (
            <ThumbsUp
              size={commentLevel == "two" ? " 18px" : "21px"}
              color="gray"
            />
          )}
          <Text
            marginTop={commentLevel !== "two" && "4px"}
            color="gray"
            fontSize={{
              sm: "14px",
              md:
                commentLevel == "top"
                  ? "14px"
                  : commentLevel == "two"
                  ? " 12px"
                  : "14px",
            }}
            fontWeight="800"
          >
            Like
          </Text>
        </>
      )}
      {isPanelVisible && (
        <Flex
          direction="row"
          position="absolute"
          bottom="40px"
          left="-20px"
          background="white"
          border="1px solid #ccc"
          borderRadius="30px"
          padding="5px"
          zIndex={100}
          gap={"1em"}
          p={"0.8em"}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          {reactionArray.map((itm, key) => {
            return (
              <Box
                key={key}
                cursor="pointer"
                _hover={{
                  transform: "scale(1.3)",
                  transition: "transform 0.2s ease-in-out",
                }}
              >
                <FacebookEmoji
                  type={itm.type}
                  size={
                    commentLevel == "top" || commentLevel == "two" ? "xs" : "sm"
                  }
                  onClickHandler={handleEmojiHandler}
                  lable={itm.lable}
                />
              </Box>
            );
          })}

          {/* {reactions?.map((reaction, index) => (
            <Tooltip
              key={index}
              label={`${reaction.reactionType}`}
              placement="top"
              marginBottom={"1em"}
            >
              <HStack
                onClick={(e) => {
                  handleEmojiHandler(e, reaction.reactionType);
                }}
                cursor="pointer"
                _hover={{
                  transform: "scale(2)",
                  transition: "transform 0.2s ease-in-out",
                }}
              >
                <Text fontSize="18px">{reaction.reaction}</Text>
              </HStack>
            </Tooltip>
          ))} */}
        </Flex>
      )}
    </HStack>
  );
};
