import { Box, Button, Center, Divider, HStack, Image, Text, Flex, Switch, Input } from "@chakra-ui/react";
import { Card, Modal } from "antd";
import { X } from "lucide-react";
import React from "react";
import { useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import mentionStyles from "@/styles/mentionStyles";
import mentionsInputStyles from "@/styles/mentionsInputStyles";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { getClassDetailsForTeacher } from "@/api/learn/teacher/class";
import { getAllSolutionForTeacher } from "@/api/learn/teacher/class";
import { useMutation, useQuery } from "react-query";
import { postSolutionByTeacher } from "@/api/learn/teacher/class";

const TeacherSolutionModal = ({ isOpen, onClose, item, postId }) => {
  const fileInputRef = useRef(null);
  const formData = new FormData();
  const [solutionTitle, setSolutionTitle] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [text, setText] = useState("");
  const { _id: uid, profilePic } = useSelector((state) => state.userData);
  const { query } = useRouter();
  const [topicId, setTopicId] = useState("");
  const { data: res } = useQuery({
    queryKey: ["getClassDetailsForTeacher", query.classId],
    queryFn: () => getClassDetailsForTeacher(query.classId, uid),
    onSuccess: () => setTopicId(res?.data?.data?.classDetail?.topic._id),
    enabled:!!query.classId
  });
  const { data: res2 } = useQuery({
    queryKey: ["getAllSolutionForTeacher", topicId],
    queryFn: () => getAllSolutionForTeacher(topicId, uid),
    enabled: !!topicId,
  });
  const [solution, setSolution] = useState([]);
  console.log(res2, "res2");

  const handleFileInputClick = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };
  const resetState = () => {
    setSolutionTitle("");
    setText("");
    setSolution([]);
    setSelectedFiles([]);
  };
  const handleSendSolution = () => {
    const formData = new FormData();
    formData.append("commentId", item?._id);
    formData.append("uid", uid);
    formData.append("replyType", "solution");
    formData.append("title", solutionTitle);
    formData.append("solutionId", solution);
    formData.append("description", "");
    for (let index = 0; index < selectedFiles.length; index++) {
      formData.append(`file${index}`, selectedFiles[index]);
    }
    mutation.mutate(formData)
  };
  const mutation = useMutation({
    mutationFn: (payload) => postSolutionByTeacher(payload, uid),
    onSuccess: () => {
      resetState();
      onClose();
    },
  });
  console.log(solution)

  return (
    <Modal
      footer={true}
      open={isOpen}
      maskClosable={true}
      centered
      onCancel={() => {
        resetState();
        onClose();
      }}
    >
      <Box position="relative">
        <Flex justifyContent={"flex-start"} gap={"2em"} width={"90%"}>
          <Text fontWeight={"700"}>Is this a Solution</Text>
          <Switch isChecked={true}></Switch>
        </Flex>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileInputClick}
          multiple
          accept=".jpg, .jpeg, .png, .gif, .mp4, .avi, .mov, .flv"
        />
        <Button
          onClick={() => fileInputRef.current.click()}
          bg="gray"
          color="#fff"
          margin="2px auto"
          fontSize="12px"
          variant="solid"
        >
          Add Photo or Video
        </Button>
        {selectedFiles && Array.from(selectedFiles).map((file, index) => <p key={index}>{file.name}</p>)}
        <Input
          sx={{
            padding: "10px",
            marginTop: "1em", // or use 'px': '10px', 'py': '10px' for specific directions
          }}
          placeholder="+ Add a solution Title"
          value={solutionTitle}
          onChange={(e) => setSolutionTitle(e.target.value)}
        />
        <Flex padding="1em 0" direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={"4px"}>
          <Image src={profilePic} width={"50px"} height={"50px"} />
          <Box minWidth={"80%"}>
            {" "}
            <MentionsInput
              width="full"
              placeholder=""
              value={text}
              onChange={(e) => setText(e.target.value)}
              // onInput={handleTypingStart}
              // onBlur={(e) => {
              //   if (!e.target.value.trim()) {
              //     setIsTyping(false);
              //   }
              // }}
              style={mentionsInputStyles}
            >
              <Mention
                trigger={"@"}
                data={res2?.data.data.solutionList.map((i) => {
                  return { display: i.title, id: i._id };
                })}
                onAdd={(e) => {
                  setSolution((prev) => [...prev, e]);
                }}
                style={mentionStyles}
                appendSpaceOnAdd={true}
              />
            </MentionsInput>
          </Box>
          <Text fontWeight={"700"}>Solutions</Text>
        </Flex>
        <Button
          width="full"
          sx={{
            bg: "black !important",
            color: "#fff",
            margin: "0 auto",
            fontSize: "12px",
          }}
          variant="solid"
          onClick={handleSendSolution}
          isLoading={false}
        >
          Send
        </Button>
      </Box>
    </Modal>
  );
};

export default TeacherSolutionModal;
