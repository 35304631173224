import { useState } from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { MessageCircle, Share2 } from "lucide-react";
import { useSelector } from "react-redux";
import { ReactionSelector } from "./reactionSelector";
import { CommentPanel } from "./commentPanel";

export const ReactionPanel = ({
  postId,
  userReaction,
  isOpenComment,
  onToggleComment,
  triggeredFrom,
}) => {
  const { _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);

  const debounce = (func, delay) => {
    return () => {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = setTimeout(func, delay);
    };
  };

  return (
    <Flex align="center" justify="center">
      <HStack
        width="33.4%"
        justify="center"
        textAlign="center"
        _hover={{ bg: "gray.200" }}
        padding="5px"
      >
        <ReactionSelector
          postId={postId}
          initialReaction={userReaction}
          triggeredFrom={triggeredFrom}
          uid={uid}
          groupId={groupId}
        />
      </HStack>
      <HStack
        cursor="pointer"
        onClick={() => {
          onToggleComment(postId);
        }}
        width="33.4%"
        justify="center"
        _hover={{ bg: "gray.200" }}
        textAlign="center"
        padding="5px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 48 48"
        >
          <path
            d="M 24 4 C 12.972292 4 4 12.972292 4 24 C 4 27.275316 4.8627078 30.334853 6.2617188 33.064453 L 4.09375 40.828125 C 3.5887973 42.631528 5.3719261 44.41261 7.1757812 43.908203 L 14.943359 41.740234 C 17.671046 43.137358 20.726959 44 24 44 C 35.027708 44 44 35.027708 44 24 C 44 12.972292 35.027708 4 24 4 z M 24 7 C 33.406292 7 41 14.593708 41 24 C 41 33.406292 33.406292 41 24 41 C 20.997029 41 18.192258 40.218281 15.744141 38.853516 A 1.50015 1.50015 0 0 0 14.609375 38.71875 L 7.2226562 40.78125 L 9.2851562 33.398438 A 1.50015 1.50015 0 0 0 9.1503906 32.263672 C 7.7836522 29.813476 7 27.004518 7 24 C 7 14.593708 14.593708 7 24 7 z"
            transform="rotate(260 24 24)" // This applies the 90-degree rotation
          ></path>
        </svg>
        <Text
          fontSize={{ sm: "12px", md: "15px" }}
          color="gray"
          fontWeight="800"
        >
          Comment
        </Text>
      </HStack>
      <HStack
        cursor="pointer"
        width="33.4%"
        justify="center"
        textAlign="center"
        _hover={{ bg: "gray.200" }}
        padding="5px"
      >
        <Share2 size="21px" color="gray" />
        <Text
          fontSize={{ sm: "12px", md: "14px" }}
          color="gray"
          fontWeight="800"
        >
          Share
        </Text>
      </HStack>
    </Flex>
  );
};
